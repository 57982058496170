import type { CollectionSchema } from '@next-space/fe-api-idl';
import { BlockType, CollectionSchemaType } from '@next-space/fe-api-idl';
import { omit } from 'lodash-es';
import type { NextBlock } from 'src/redux/types';
import { v4 as uuidV4 } from 'uuid';

export const dataNeedForCopy = (block: NextBlock) => {
  const omitBlock = omit(block, [
    'updatedAt',
    'deletedAt',
    'createdBy',
    'createdAt',
    'subNodes',
    'parentId',
    'spaceId',
    'version',
    'permissions',
    'views',
  ]);
  // 不这么做的话，下面修改data属性会报错
  const _block = {
    ...omitBlock,
    data: {
      ...omitBlock.data,
    },
  };
  const newId = uuidV4();
  _block.uuid = newId;
  if (block.type === BlockType.COLLECTION_VIEW || block.type === BlockType.COLLECTION_VIEW_PAGE) {
    const schema = _block.data.schema ?? {};
    const newSchema: Record<string, CollectionSchema> = {};
    Object.entries(schema).forEach(([key, propertySchema]) => {
      if (propertySchema.type === CollectionSchemaType.RELATION) {
        if (propertySchema.collectionId === block.uuid) {
          newSchema[key] = { ...propertySchema, collectionId: newId }; // 指向自己的关联属性拷贝时需修改 collectionId)
        } else {
          newSchema[key] = { ...propertySchema };
        }
      } else {
        newSchema[key] = { ...propertySchema };
      }
    });
    if (!_block.data) {
      _block.data = {};
    }
    _block.data.schema = newSchema;
  }
  return _block;
};

/** 菜单复制同步块 */
export const copySyncBlock = (block: NextBlock, syncBlockId: string) => {
  if (block.type === BlockType.SYNC_CONTAINER) {
    block.type = BlockType.SYNC_REFERENCE;
    block.data.ref = {
      uuid: syncBlockId,
    };
    block.subNodes = [];
  }
};
